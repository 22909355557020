import React, {useContext, useEffect,useRef, useState } from "react"
import { graphql, navigate } from "gatsby";
import classNames from "classnames";
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import Link from "../components/link";
import { getSinglePage, isMobile } from "../utils";
import Layout from "../components/wrappers/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import BlogList from "../components/blog-list"
import { GlobalDispatchContext, GlobalStateContext } from "../context/GlobalContextProvider"
import SiteHeaderAlt from "../components/wrappers/site-header-alt"
import Header from '../components/header';
import TabbedItem from '../components/wrappers/tabbed-item';
import { domain } from '../utils';
import TabbedList from '../components/wrappers/tabbed-list';
import ServiceCard from "../components/service-card";
import Markdown from "markdown-to-jsx";
import CaseList from '../components/case-list';
import CardArticle from "../components/card-article";
import { CustomSectionInner } from "../components/wrappers/CustomSectionInner";
import iconRefresh from "../images/refresh.svg";
import menu from "../images/menu.svg";
import TextMorpher from "../components/dynamicWords";
import { CustomSvg } from "../components/CustomSvg";
import { CustomHr } from "../components/CustomHr";
import MouseHover from "../components/mouseHover";


const IndexPage = React.memo( ({ data }) => {
  const { homepage, headers, footers, general } = data.strapi;
  const { generalBackgroundColorDarkMode, generalBackgroundColorLightMode , fontColorDarkMode, fontColorLightMode, hashtagsBackgroundColor, hashtagsBackgroundColorDarkMode, clientLogosColorDarkMode, clientLogosColorLightMode, pageTransitionBgColorLightMode, pageTransitionBgColorDarkMode  } = general; 
  const { blogs, branchenSection, copyright } = homepage;
  const header = headers && headers.find((header)=>  header.language === homepage.language );
  const footer = footers && footers.find((footer)=>  footer.language === homepage.language ) || {};
  const dispatch = useContext(GlobalDispatchContext);
  const state = useContext(GlobalStateContext);
  const darkMode = state.darkModePreference === 'moon'; 
  const {
    language,
    caseStudyList,
    supBodyLink,
    services,
    servicesHeading,
    sup,
    blogHeading,
    blogLink,
    supHeading,
    heading,
    articles,
    introCollages,
    stats,
    contacts,
    doYourBest,
    contactsBody,
    darkTheme
  } = homepage
  const sortedBlogs = blogs.sort((a, b) => {
    return new Date(b.date) - new Date(a.date);
   })
  
  const caseStudies = caseStudyList && caseStudyList.filter((caseStudy) => !caseStudy.inactive).map((caseStudy,index) => {
    return {...caseStudy.case_study,language:language,id:index}
  });
  // getting  page first H1 to set as meta title and description if not set in strapi .
  const pageFirstHeading = supBodyLink[0].sup + " " + (supBodyLink[0].text ? supBodyLink[0].text?.heading:"");
  const seoTitle = homepage.seo && homepage.seo.metaData.metaTitle || pageFirstHeading ||"homepage";
  const seoDescription = homepage.seo && homepage.seo.metaData.metaDescription || pageFirstHeading|| " homepage";
  const seoKeywords = homepage.seo && homepage.seo?.metaData?.keywords;
  const [randomCaseStudies, setRandomCaseStudies] = useState([]); 
  const caseStudySectionRef = useRef(null);
  const blogArticleSectionRef = useRef(null);
  const btnRef = useRef(null);
  const blogArticleBtnRef = useRef(null);
  const [isSticky, setIsSticky] = useState(false);
  const [isBlogArticleSticky, setBlogArticleSticky] = useState(false);
  
  const [hideBtn, setHideBtn] = useState(false);
  const breakpoints = useBreakpoint();
  const allPages = data?.allSitePage?.nodes; 
  const webpages = homepage && homepage.webpages || {}
  const redirectTo = getSinglePage(webpages, allPages); 
  const [index, setIndex] = useState(0);
  const dynamicWords = supBodyLink[0]?.text?.dynamicWords?.length > 0 && supBodyLink[0]?.text?.dynamicWords?.split(",").map(word => word.trim()); 
  const wordToReplace = dynamicWords && dynamicWords[0]; 
  const primaryBtn = general?.primaryBtn;
  const imageBorderRadiusForServices = general?.imageBorderRadiusForServices; 
  const greySectionLightModeBgColor = general?.greySectionLightModeBgColor; 
  const greySectionDarkModeBgColor = general?.greySectionDarkModeBgColor; 
  const greySectionBgColor = darkMode ? greySectionDarkModeBgColor : greySectionLightModeBgColor; 
  const sectionBackgroundColor = darkMode ? generalBackgroundColorDarkMode : generalBackgroundColorLightMode; 
  const websiteFontColor = darkMode ? fontColorDarkMode : fontColorLightMode; 
  const filteredRelatedCards = articles?.relatedCard.filter(relatedCard => !relatedCard?.blog?.inactive || relatedCard?.case_study?.inactive); 
  const logosBgColor = darkMode ? clientLogosColorDarkMode : clientLogosColorLightMode; 
  const displayedHashtagBgColor = darkMode ? hashtagsBackgroundColorDarkMode : hashtagsBackgroundColor;
  const pageTransitionBgColor = darkMode ? pageTransitionBgColorDarkMode : pageTransitionBgColorLightMode; 
  
  if (copyright) {
    footer.copyright = copyright;
  }
  
  const renderTextWithSpan = (text) => {
    if(!wordToReplace) return text; 
    const pattern = new RegExp(wordToReplace, "g");
    const replacedText = text.replace(pattern, `<span class="dynamic-word" key={${index}}>${dynamicWords[index]}</span>`);
    return <div dangerouslySetInnerHTML={{ __html: replacedText }} />;
  };
  
  function getMeRandomCaseStudies (sourceArray, neededElements) {
    let items = sourceArray;
    let newItems = [];

    for (var i = 0; i < neededElements; i++) {
      var idx = Math.floor(Math.random() * items.length);
      newItems.push(items[idx]);
      items.splice(idx, 1);
    }

    return newItems;
}
const [hovered, sethovered] = useState(""); 
  function gethovered(state) {
    sethovered(state)
  }

const handleClick = (url) => {
  if (url) {
    navigate(url);
    return;
  }
  setRandomCaseStudies(getMeRandomCaseStudies(caseStudies, 4));
};

useEffect(() => {
  setTimeout(
    () =>
      setIndex((current) => (current < dynamicWords.length - 1 ? current + 1 : 0)),
    4000
  );
}, [index]);

useEffect(() => {
  if (state.previousUrl) {
    dispatch({ type: "OFF_SWITCHER_ANIMATION"});
  }
  setTimeout(() => {
    dispatch({ type: "TURN_SWITCHER_ANIMATION"});
  }, 1000); 
  setRandomCaseStudies(getMeRandomCaseStudies(caseStudies,4)); 
  
  if (state.isPageTransition.isPageTransitionActive) {
    setTimeout(() => {
      dispatch({ type: "TOGGLE_SITE_PAGE_TRANSITION"});
    }, 1000)    
  }
  return () => {
    dispatch({type: 'SET_PREVIOUS_URL', payload: ""}); 
    dispatch({ type: 'OFF_SWITCHER_ANIMATION'});
    dispatch({type: 'CLOSE_SWITCHER'});
  }
},[]);


useEffect(() => {
  const options = {
    root: null,
    rootMargin: "0px",
    threshold: 0.2,
  };

  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    });
  }, options);



  const blogArticlerObserver = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        setBlogArticleSticky(true);
      } else {
        setBlogArticleSticky(false);
      }
    });
  }, options);
  if(blogArticlerObserver && blogArticleSectionRef.current) {
    blogArticlerObserver.observe(blogArticleSectionRef.current)
  }

  observer.observe(caseStudySectionRef.current);

  return () => {
    observer.disconnect();
    blogArticlerObserver.disconnect();
  };
}, [caseStudySectionRef, blogArticleSectionRef]); // Corrected the placement of the closing parenthesis



useEffect(() => {
  if(!isMobile()) return; 
  const handleScroll = () => {
    // This will log the updated value of isSticky
    const btnBottom = btnRef?.current?.getBoundingClientRect()?.bottom; 
    const blogArticleBtnBottom = blogArticleBtnRef?.current?.getBoundingClientRect()?.bottom; 

    const casesSectionBottom = caseStudySectionRef?.current?.getBoundingClientRect()?.bottom; 
    const blogArticleSectionBottom =  blogArticleSectionRef?.current?.getBoundingClientRect()?.bottom; 
    if (btnBottom > (casesSectionBottom - 40) && isSticky) {
      btnRef.current.classList.add("invisible")
    } else {
      btnRef.current.classList.remove("invisible")
    }

    if (blogArticleBtnBottom > (blogArticleSectionBottom - 40) && isBlogArticleSticky) {
      blogArticleBtnRef.current.classList.add("invisible")
    } else {
      blogArticleBtnRef.current &&  blogArticleBtnRef.current.classList.remove("invisible")
    }
  };

  document.body.addEventListener("scroll", handleScroll);
  // Cleanup the event listener when the component unmounts
  return () => {
    document.body.removeEventListener("scroll", handleScroll);
  };
}, [isSticky, isBlogArticleSticky]);


return (
    <Layout header={header} footer={footer} language={language} redirectTo={redirectTo} isHomepage={true}>
      <SEO
        websiteFontColor={websiteFontColor} 
        template="homepage" 
        title={seoTitle} 
        description={seoDescription}
        keywords={seoKeywords} 
        darkTheme={darkTheme} 
        darkMode={darkMode}
        websiteBackgroundColor={sectionBackgroundColor}
      />
      {/* <Hero heroEntries={caseStudyList} buttonLabel={caseStudyButtonLabel} /> */}
      <SiteHeaderAlt header={header && header} redirectTo={redirectTo}>
         {supBodyLink && supBodyLink.map(({ sup, text , link ,document}, index) => (
          <section key={index} className="section section-first--reduce section-small-margin" style={{backgroundColor: sectionBackgroundColor}}>
            {text?.heading && (
              <Header 
                largeHeadingClassName={"header__heading--large"} 
                supheading={sup} 
                heading={renderTextWithSpan(text?.heading && text?.heading)}                 
                largeHeaderText={true} 
              />
            )}
            <div className="container">
              {(text?.description && text.description.length > 0 ) && (
                <div className="row what-create-digital">
                  <div className="col-lg-8 offset-lg-2">
                    <div className="about__item-body rich-text">
                    {text.description && (                  
                      <Markdown options={{
                        overrides: {a: {
                          component: Link
                        },                           
                        hr: { 
                            component: CustomHr,
                            props: {
                              borderBottom: `1px solid ${websiteFontColor}` // Pass borderTop style directly as a prop
                            }
                          }  
                        }
                      }}>
                        {text.description}
                      </Markdown>
                    )}   
                    </div>
                      {document && (
                       <a href={`${document.document && document.document.url}`} className="button" target="_blank">
                        <span className="button__text">{document.label}</span>
                      </a>
                      )}
                      {link && (
                        <Link
                          className="button"
                          to={`${link.path}`}
                        >
                          <span className="button__text">{link.label}</span>
                        </Link>                      
                      )}
                  </div>
                </div>
              )}
            </div>
          </section>
        ))}
        {(branchenSection && branchenSection.showBranchenSection && branchenSection?.serviceUxes?.length > 0 ) && (
          <section className="section">
            <div className="container"> 
              <div className="row">
                  <div className="col-md-6 col-lg-9">
                    <header className="header">

                      {branchenSection.heading && (
                        <h1 className={`header__heading header__heading--space `}>
                          {branchenSection.heading}
                        </h1>
                      )}
                    </header>
                  </div>
              </div>

              <div className={classNames(breakpoints.sm ? "blog-list--compact" : "row")}>
                {branchenSection.serviceUxes.map((service, index) => {
                  return (
                    <div key={`service-${index}`} className={classNames(breakpoints.sm ? "branches-item blog-list__item" : "col col-sm-3 col-md-3")}>
                      <Link to={`/${service?.language}/industries/${service?.slug}`} className={'position-relative branches-item__link'}>
                        <MouseHover
                          gethovered={false}
                          classId={`case-list__image nocursor case-list__image--hover` } 
                        >
                          {(service?.previewImage?.url ) && (
                            <img src={service?.previewImage?.url} style={{borderRadius: imageBorderRadiusForServices}}/>
                          )}
                        </MouseHover>
                        <h3 className="case-list__heading case-list__heading--abt font-weight-bold position-absolute ">{ service?.previewText || service.slug}</h3>
                      </Link>
                    </div>
                  )
                })}
              </div>
            </div>
          </section>
        )}
        {language === "en" && articles && (
          <section className="section blog-related cards-articles" style={{backgroundColor: sectionBackgroundColor}}>
          <div className="container">
            <div className="row">
              <div className="col-md-6 offset-lg-1 col-lg-9">
                <header className="blog-related__header header">
                  <h1 className="header__heading header__heading--space">
                    {/* <small className="header__preheading">{articles && articles.sup}</small> */}
                    {articles && articles?.heading}
                  </h1>
                </header>
              </div>
            </div>
            <div className="row">
              <div className="offset-lg-1 col col-lg-10">
                {articles && filteredRelatedCards  && (
                  <div className="row card-articles">
                    {filteredRelatedCards.map((relatedCard) => {
                      const article = relatedCard.blog || relatedCard.case_study;

                      return (
                        <CardArticle 
                          article={article} 
                          path={relatedCard.blog ? 'blog' : 'case-studies'} 
                          imageBorderRadiusForServices={imageBorderRadiusForServices} 
                          hashtagsBackgroundColor={displayedHashtagBgColor}
                          sectionBackgroundColor={sectionBackgroundColor}
                        />
                      )
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
        )}
        <section className="section" style={{backgroundColor: sectionBackgroundColor}}>
          <div className="container">
            <div className="row">
              <div className="col-md-10 col-lg-10 random-heading">
                {heading && (<Header supheading={supHeading} heading={heading} />)}
              </div>
            </div>
          </div>
          {(caseStudies && caseStudies.length > 0) && (
           <div className="container" ref={caseStudySectionRef} id="caseStudySection">
            <div className="row">
              <div className="offset-lg-1 col-lg-10">
                <CaseList 
                  caseStudies={randomCaseStudies && randomCaseStudies} 
                  oddElementMarginTop={true}  
                  imageBorderRadiusForServices={imageBorderRadiusForServices}
                  websiteFontColor={websiteFontColor}
                  hashtagsBackgroundColor={displayedHashtagBgColor}
                  pageTransitionBgColor={pageTransitionBgColor}
                />
              </div>
            </div>
           </div>
          )}
        </section>
        {services && (
          <section className={classNames("section", {"section--higher-z-index": breakpoints.sm})} style={{backgroundColor: sectionBackgroundColor}}>
            {language === "de" && servicesHeading && (
              <Header  heading={servicesHeading && servicesHeading } />
            )}
            {language === "en" && servicesHeading && (
              <Header  heading={servicesHeading} />
            )}
            <div className="tabbed container-lg container">
              {(services && services.length > 0) && (
                <TabbedList>
                  {services.map((service, index) => (
                    <TabbedItem 
                      id={( index + 1 < 10 ? "0" : "")+(index+1)} 
                      group="services" 
                      heading={service?.heading} 
                      initiallyChecked={index === 0}
                      imageBorderRadiusForServices={imageBorderRadiusForServices}
                    >
                      <ServiceCard 
                        service={service} 
                        showHeading={false} 
                        language={language} 
                        imageBorderRadiusForServices={imageBorderRadiusForServices}
                        websiteFontColor={websiteFontColor}
                      />
                    </TabbedItem>
                  ))}
                </TabbedList>
              )}
            </div>
          </section>
        )}

        {language === "en" && (
          <>
           {introCollages && (
              <section className="section" style={{backgroundColor: sectionBackgroundColor}}>
                {introCollages.header && (
                  <Header heading={introCollages.header?.heading} supheading={introCollages.header.supheading} />
                )}
                <div className="container">
                  <div className="row">
                    <div className="offset-md-2 col-md-7">
                      <Markdown options={{
                        overrides: {a: {
                          component: Link
                        },                           
                        hr: { 
                            component: CustomHr,
                            props: {
                              borderBottom: `1px solid ${websiteFontColor}` // Pass borderTop style directly as a prop
                            }
                          }  
                        }
                      }}>
                        {introCollages.body}
                      </Markdown>
                    </div>
                  </div>
                </div>
                {(introCollages.collage && introCollages.collage.length > 0) && (
                  <div className="collage">
                    <div className="container">
                      <div className="row collage__inner">
                        {introCollages.collage[0] && (
                          <div className="offset-md-1 col-md-3 collage-item">
                            <img 
                              src={introCollages.collage[0].url} 
                              style={{borderRadius: imageBorderRadiusForServices}}
                              alt={introCollages.collage[0].alternativeText} 
                              loading="lazy"
                            />
                          </div>
                        )}
                        {introCollages.collage[1] && (
                          <div className="offset-md-1 col-md-6 collage-item">
                            <img 
                              src={introCollages.collage[1].url} 
                              style={{borderRadius: imageBorderRadiusForServices}}
                              alt={introCollages.collage[1].alternativeText} 
                              loading="lazy"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </section>
            )}

            {stats && (
              <section 
                className="section section--grey stats" 
                style={{backgroundColor: greySectionBgColor}}
              >
                {stats.header && (
                  <Header heading={stats.header?.heading} supheading={stats.header.supheading} withFullWidth={false}/>
                )}

                {(stats.stat && stats.stat.length > 0) && (
                  <div className="container">
                    <div className="row">
                      <div className="col-12 stats__list">
                        {stats.stat.map((stat) => {
                          return (
                            <div className="stats__stat">
                              <h2>{stat.value}</h2>
                              <h3 className="light-font" style={{color: websiteFontColor }}>{stat.label}</h3>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                )}
              </section>
            )}
          {doYourBest && (
            <section className="section do-your-best" style={{backgroundColor: sectionBackgroundColor}}>
              <Header supheading={doYourBest.sup} heading={"Do the best work of your life"} largeHeaderText={false} withFullWidth={false}/>
              <div className="container">
                {(doYourBest.description && doYourBest.description.length > 0) && (
                  <div className="row what-create-digital">
                    <div className="col-lg-8 offset-lg-2">
                      <div className="about__item-body  rich-text">
                        <Markdown options={{
                          overrides: {a: {
                            component: Link
                          },                           
                          hr: { 
                              component: CustomHr,
                              props: {
                                borderBottom: `1px solid ${websiteFontColor}` // Pass borderTop style directly as a prop
                              }
                            }  
                          }
                        }}>                          
                          {doYourBest.description}
                        </Markdown>
                      </div>
                    </div>
                  </div>
                )}
                </div>
            </section>
          )}
          {contacts && (
              <section className="section say-hello" style={{backgroundColor: sectionBackgroundColor}}>
                {contacts.header && (
                  <Header heading={contacts.header?.heading} supheading={contacts.header.supheading} />
                )}
                {contactsBody && (
                  <div className="container">
                    <div className="row">
                      <div className="rich-text col-lg-8 offset-lg-2">
                        <Markdown options={{
                          overrides: {a: {
                            component: Link
                          },                           
                          hr: { 
                              component: CustomHr,
                              props: {
                                borderBottom: `1px solid ${websiteFontColor}` // Pass borderTop style directly as a prop
                              }
                            }  
                          }
                        }}>                            
                          {contactsBody}
                        </Markdown>
                      </div>
                    </div>
                  </div>

                )}
                <div className="container contact-info say-hello__contact">
                  {(contacts.contactperson && contacts.contactperson.length > 0) && (
                    <div className="row">
                      {contacts.contactperson.map((contactperson, index) => {
                        const isOdd = index % 2;

                        return (
                          <>
                            <div className={'col-4 col-sm-3 col-tw-2 col-lg-1 '+(isOdd ? '' : 'offset-lg-2')}>
                              {contactperson.media && (
                                <img src={contactperson.media.url} alt={contactperson.media.alternativeText} loading="lazy" />
                              )}
                            </div>
                            {contactperson.body && (
                              <div className="col-8 col-sm-9 col-tw-4 col-lg-3 contact-info__body">
                                <h4 className="contact-info__heading">{contactperson.body?.heading}</h4>
                                {contactperson.body.richText && (
                                  <div className="rich-text">
                                    <Markdown options={{
                                      overrides: {a: {
                                        component: Link
                                      },                           
                                      hr: { 
                                          component: CustomHr,
                                          props: {
                                            borderBottom: `1px solid ${websiteFontColor}` // Pass borderTop style directly as a prop
                                          }
                                        }  
                                      }
                                    }}>
                                      {contactperson.body.richText}</Markdown>
                                  </div>
                                )}

                              </div>
                            )}
                          </>
                        )
                      })}
                    </div>
                  )}
                  <div className="row">
                    <div className={'col-4 col-sm-3 col-tw-2 col-lg-1 '+('offset-lg-2')}>
                      {contacts?.address?.media && (
                        <img src={contacts?.address?.media.url} alt={contacts?.address?.media.alternativeText} loading="lazy" />
                      )}
                    </div>
                   <div className="col-6 col-tw-4 col-lg-3 contact-info__body">
                      {contacts?.address?.heading && (
                        <h4 className="contact-info__heading">{contacts?.address?.heading}</h4>
                      )}
                      {contacts?.address.richText && (
                        <div className="rich-text">
                          <Markdown options={{
                            overrides: {a: {
                              component: Link
                            },                           
                            hr: { 
                                component: CustomHr,
                                props: {
                                  borderBottom: `1px solid ${websiteFontColor}` // Pass borderTop style directly as a prop
                                }
                              }  
                            }
                          }}>
                            {contacts.address.richText}</Markdown>
                        </div>
                      )}

                    </div>
                  <div className={'col-4 col-sm-3 col-tw-2 col-lg-1 '}>
                    {contacts?.directions?.media && (
                      <img src={contacts?.directions?.media.url} alt={contacts?.directions?.media.alternativeText} loading="lazy" />
                    )}
                  </div>    
                  <div className="col-6 col-tw-4 col-lg-3 contact-info__body">
                      <h4 className="contact-info__heading">{contacts.directions?.heading}</h4>
                      <div className="rich-text">
                        <Markdown 
                          options={{
                            overrides: {a: {
                              component: Link
                            },                           
                            hr: { 
                                component: CustomHr,
                                props: {
                                  borderBottom: `1px solid ${websiteFontColor}` // Pass borderTop style directly as a prop
                                }
                              }  
                            }
                        }}>
                          {contacts.directions.richText}
                        </Markdown>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            )}

          </>
        )}

        {(blogs?.length > 0 && language === "de") && (
         <section 
           className="section section--grey section--grey-small" 
           ref={blogArticleSectionRef}
           style={{backgroundColor: greySectionBgColor}}
          >
            {blogHeading && (
              <Header supheading={sup} heading={blogHeading} withFullWidth={true} />
            )}
            {blogs && (
              <div className="container">
                <BlogList 
                  blogEntries={sortedBlogs} 
                  imageBorderRadiusForServices={imageBorderRadiusForServices}
                  hashtagsBackgroundColor={displayedHashtagBgColor}
                  pageTransitionBgColor={pageTransitionBgColor}
                  sectionBackgroundColor={sectionBackgroundColor}
                />
              </div>
            )}
        </section>
        )}
          <button 
            className={classNames("anchor-nav-btn", {"active": isSticky}, {"invisible": hideBtn})}
            ref={btnRef}
            onClick={() => handleClick()}  
          >
            <span>{primaryBtn || 'Show other cases'}</span> 
            <span className="anchor-nav-btn-refresh">
              <img  src={iconRefresh}/>
            </span>
          </button>
          {blogLink && (
            <button 
              className={classNames("anchor-nav-btn anchor-nav-btn--z-index-s", {"active": isBlogArticleSticky}, {"invisible": hideBtn})}
              ref={blogArticleBtnRef}
              onClick={() => handleClick(`/${language}/${blogLink && blogLink.path}`)}  
            >
              <span>{blogLink.label}</span> 
              <span className="anchor-nav-btn-refresh">
                <img  src={menu}/>
              </span>
            </button>
          )}
      </SiteHeaderAlt>
    </Layout>
  )
})

export default IndexPage

export const pageQuery = graphql`
  query ($id: ID!, $language: String!) {
    allSitePage {
      nodes {
        path
      }
    }
    strapi {
      general {
        primaryBtn
        blogsOverviewHeadline
        imageBorderRadiusForServices
        clientLogosColorDarkMode
        clientLogosColorLightMode 
        hashtagsBackgroundColor
        hashtagsBackgroundColorDarkMode
        greySectionLightModeBgColor
        greySectionDarkModeBgColor
        generalBackgroundColorDarkMode
        generalBackgroundColorLightMode
        fontColorDarkMode
        fontColorLightMode
        pageTransitionBgColorLightMode
        pageTransitionBgColorDarkMode
      }
      homepage(id: $id) {
        copyright
        darkTheme
        blogs(limit: 6, sort: "dateValue:desc") {
          ...BlogListItem
        }
        branchenSection {
          heading
          showBranchenSection
          serviceUxes {
            slug
            language
            previewImage {
              url
              ext
              alternativeText
              caption
              mime
            }
            previewText
          }
        }
         webpages {
            about_us {
              language
            }
            contact {
              language
            }
            case_study {
              slug
              language
            }
            blogs_overview {
              language
            }
            cases_overview {
              language
            }
            careers_overview {
              language
            }	
            services_overview {
              language
            }
            blog {
              language
              slug
            }
            career {
              language
              slug
            }
            homepage {
              slug
              language
            }
            service_design {
              slug
              language
            }
            service_technical {
              slug
              language
            }
            service_casey_new {
              slug
              language
            }
            service_casey {
              slug
              language
            }
            plainpage {
              language
              slug
            }
          }
          supHeading
          heading
          servicesHeading
          contactsBody
          seo {
            metaData {
              metaTitle
              metaDescription
              keywords
            }
          }
          introCollages {
            header {
              supheading
              heading
            }
            body
            collage {
              url
              alternativeText
              mime
              ext
            }
          }
          language
          caseStudyList {
            case_study {
              language
              inactive
              headerImageMobile {
                url
              }
              clientLogo {
                logo {
                  url 
                }
              }
              headerImage {
                url
              }
              title
              slug
              tags {
                tag
                active
              }
              previewImage {
                url 
              }
              description
              backgroundColor
            }
          }
          caseStudyButtonLabel
          supBodyLink {
            sup
            text {
              heading
              description
              dynamicWords
            }
            link {
              label
              path
            }
            document {
              label
              document {
                url
              }
            }
          }
          services {
            heading
            media {
              media {
                url
              }
            }
            body {
              richText
            }
            link {
              label
              path
            }
          }
          sup
          blogHeading
          blogLink {
            label
            path
          }
          articles {
            sup
            heading
            relatedCard {
              blog {
                ...CardArticleBlog
              }
              case_study {
                ...CardArticleCaseStudy
              }
            }
          }
          stats {
            header {
              supheading
              heading
            }
            stat {
              value
              label
            }
          }

          contacts {
            header {
              supheading
              heading
            }
            contactperson {
              media {
                url
                alternativeText
                mime
                ext
              }
              body {
                heading
                richText
              }
            }
            address {
              heading
              richText
              media {
                url
                alternativeText
                mime
                ext
              }
            }
            directions {
              heading
              richText
               media {
                url
                alternativeText
                mime
                ext
              }
            }
          }
          doYourBest {
            sup
            heading
            description
          }
      }

      headers(where: {language: $language}) {
        ...SiteHeader
      }
      footers(where: { language: $language }) {
        ...SiteFooter
      }
    }
  }
`
