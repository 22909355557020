import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { isDesktop, isMobile } from "../../utils"

const TabbedList = ({ children }) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const [isMobileDevice, setIsMobileDevice] = useState(false)
  const [isPaused, setIsPaused] = useState(false);

  const handleClick = (index) => {
    if (isMobileDevice) {
      setActiveIndex((prevIndex) => (prevIndex === index ? -1 : index))
    } else {
      setActiveIndex(index)
    }
  }

  useEffect(() => {
    setIsMobileDevice(isMobile())
    window.addEventListener("resize", handleWindowResize)
    return () => {
      window.removeEventListener("resize", handleWindowResize)
    }
  }, [])
  
   useEffect(() => {
    let timer;
    const isDesktopDevice = isDesktop()
    if (!isPaused && isDesktopDevice) {
      timer = setTimeout(() => {
        setActiveIndex((prevIndex) => (prevIndex + 1) % children.length);
      }, 4000);
    }
    return () => clearTimeout(timer);
  }, [activeIndex, isPaused, isMobileDevice, children.length]);

  function handleWindowResize() {
    setIsMobileDevice(isMobile())
  }

  const childrenWithProps = React.Children.map(children, function(child, index){
     return (
        React.cloneElement(child, {
          index,
          onClick: handleClick,
          isOpen: isMobileDevice ? true :  index === activeIndex,
          setIsPaused  // Pass down setIsPaused to children
        })
     )
    
    }
    
  )
  const childrenCount = React.Children.count(children) >= 4;
  return <div className={classNames("tabbed__list", {"tabbed__list-large": childrenCount})}>{childrenWithProps}</div>
}

TabbedList.propTypes = {
  children: PropTypes.node.isRequired,
}

export default TabbedList
