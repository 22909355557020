import Link from "./link";
import PropTypes from "prop-types";
import React, { useState, useEffect, useContext } from "react";
import SVG from 'react-inlinesvg';
import classNames from "classnames";
import { graphql } from 'gatsby';
import {isMobile} from '../utils';
import { domain } from "../utils";
import Tags from "./tags";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { GlobalStateContext } from "../context/GlobalContextProvider";

function randomIntFromInterval(min, max) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min)
}

// setting header image source to empty string if there is no image 
const defaultHeroEntry = {
  case_study: {
    slug: "",
    headerImage: { url: "" },
    supLink: { label: "sup", path: "#" },
    title: "title",
  },
  linearGradient: false,
}

const Hero = ({ heroEntries, buttonLabel, showLink , headerFontColor, tags, hashtagsBackgroundColor , borderRadius, showclientLogo, clientLogo, logosBgColor}) => {
  const [currentHeroIndex, setCurrentHeroIndex] = useState(null);
  const [currentHero, setCurrentHero] = useState(defaultHeroEntry);
  const [mobile, setDevice] = useState(isMobile()); 
  const [fadeOut, setFadeOut] = useState(false); 

  const breakpoints = useBreakpoint();
  const state = useContext(GlobalStateContext);

  // returns true if current page is called  case-study ,so different headers should  be displayed based on  if device is mobile or  desktop . 


  useEffect(() => {
    const currentHeroIndex = randomIntFromInterval(0, heroEntries.length - 1) // Get random case study from list

    if (!heroEntries[currentHeroIndex].case_study) {
      heroEntries[currentHeroIndex].case_study = defaultHeroEntry.case_study;
    }

    const currentHero = Object.assign({}, defaultHeroEntry, heroEntries[currentHeroIndex]);

    setCurrentHeroIndex(currentHeroIndex);
    setCurrentHero(currentHero);
     
    
  }, [])

  useEffect(() => {
    if (state.isPageTransition.isPageTransitionActive) {

    }

  }, [state.isPageTransition.isPageTransitionActive]); 

  const {
    case_study: { slug, headerImage, headerImageMobile, supLink, title, backgroundColor },
    linearGradient,
  } = currentHero;
  
  if (!heroEntries || heroEntries.length === 0) { return }
  return (
    <section className={`hero  ${!headerFontColor ? "hero--white": `hero--${headerFontColor}`}`} style={{backgroundColor}}>
      <div className="container-lg container align-items-end">
        <div
          className={classNames("hero__background", {
            "hero__background--shadow": linearGradient,
          })}
        >
     
            <div className="hero__background-inner">
              <picture>
                  <source media="(max-width: 767px)" srcSet={`${headerImageMobile && headerImageMobile.url}`} />
                  <source media="(min-width: 768px)" srcSet={`${headerImage && headerImage.url}`} />
                  <img src={`${headerImage && headerImage.url}`} alt={title} />
              </picture>
            </div>

        </div>
        <div className="hero__main">
          <div className="row align-items-end">
            <div className="col-12">
              <div className="hero__content">
                <Tags 
                  tags={tags.filter((tag) => tag?.active)} 
                  whiteTags={!(headerFontColor === 'white')} 
                  noMarginBottom={true}
                  borderRadius={borderRadius}
                  hashtagsBackgroundColor={hashtagsBackgroundColor}
                  centerTags={true}
                />
                <header className="hero__header header">
                  <h1 className="header__heading">
                    {supLink?.path && (
                      <small className="header__preheading">
                        <Link to={supLink.path}>{supLink.label}</Link>
                      </small>
                    )}
                    {title}
                  </h1>
                </header>
                {showLink && (
                  <div className="hero__buttons buttons">
                    <Link to={'case-studies/'+slug} className="button button--white">
                      <span className="button__text">{buttonLabel}</span>
                    </Link>
                  </div>
                )}
                {heroEntries.length > 1 && (
                  <div className="hero__counter counter">
                    <div className="counter__numbers">
                      <span className="counter__current">
                        {currentHeroIndex + 1}
                      </span>
                      <span className="counter__separator">/</span>
                      <span className="counter_all">{heroEntries.length}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {showclientLogo && (
          <div className={classNames("page-transition-inner w-100 h-100 position-relative", {"page-transition-fill": logosBgColor !== "color"})}>
            <div className={classNames("position-absolute bottom-50 end-50 page-transition-svg", {"page-transition--fade-out": fadeOut} )} style={logosBgColor === "color" ? {} : {fill: logosBgColor }} >
              <SVG 
                src={clientLogo?.logo?.url} 
                width={breakpoints.sm ? '250px' : '300px'}
                height="auto"
              />
            </div>
          </div>
        )}
      </div>
    </section>
  )
}

Hero.propTypes = {
  heroEntries: PropTypes.array,
  showLink: PropTypes.bool
}

Hero.defaultProps = {
  heroEntries: [],
  showLink: true
}

export const query = graphql`
  fragment Hero on STRAPI_ComponentComplexCaseStudyList {
    case_study {
      slug
      headerImage {
        url
      }
        headerImageMobile{
          url
        }
      supLink {
        label
        path
      }
      backgroundColor
      title
    }
    linearGradient
  }
`

export default Hero
