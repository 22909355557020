import { Link } from "gatsby"
import React, { useState } from "react"
import Collapse from './collapse';
import classNames from "classnames"
import { isDesktop, isMobile } from "../../utils";

const TabbedItem = ({ index, id, heading, isOpen, onClick, children , setIsPaused, imageBorderRadiusForServices, isIndustriesPage }) => {
  return (
    <div className={classNames("row tabbed__item", {"open": isOpen})} role="listitem"
      onMouseEnter={() => {
        const isDesktopDevice = isDesktop()
        if (isDesktopDevice) {
          setIsPaused(true);  // Pause auto-rotate
        }
        onClick(index)
      }}
      onMouseLeave={() => {
        const isDesktopDevice = isDesktop()
        if (isDesktopDevice) {
          setIsPaused(false);  // Pause auto-rotate
        }
      }}
    >
      <div className="offset-md-1 col-md-5 tabbed__header" 
        onClick={() => {
          if (isMobile()) {
            onClick(index)
          }
        }}
      >
        <div className="tabbed__number">
          <span>{id}</span>
        </div>
        <h1 className="tabbed__heading">
          {heading}
        </h1>
      </div>
      <div
        className={classNames("offset-md-6 col-md-4 tabbed__body", {"tabbed__body--small" : isIndustriesPage})}
        data-active="true"
        aria-hidden="false"
      >
        <Collapse isOpen={isOpen}>
          <div className="tabbed__sticky" style={{borderRadius: `${imageBorderRadiusForServices} ${imageBorderRadiusForServices} 0px 0px`}}>
            <div className="tabbed__body-inner">
              {children}
            </div>
          </div>
        </Collapse>
      </div>
    </div>
  )
}

export default TabbedItem
