import { Link, navigate } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";
import BlogListItem from "./blog-list-item";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

const BlogList = ({ blogEntries, showParallax, sectionBackgroundColor,  isCompact, isBlogListOverview , isTagsDetailPage, activeDisplay, showMobileImages, imageBorderRadiusForServices, showPageTransition, hashtagsBackgroundColor, showFullWidthOnMobile, pageTransitionBgColor }) => {
  
  return (
  <div className={classNames("blog-list", { "blog-list--compact": isCompact }, {"blog-list-overview ": isBlogListOverview} , {"d-flex flex-column": activeDisplay, "row": showFullWidthOnMobile} )}>
    {blogEntries.filter(blog => !blog.inactive).map((blogEntry, i) => {
      const thumbSize = i % 2 ? "tall" : "short";
      const isLeftBlog = (i % 2 ) === 0; 
      return (
        <BlogListItem 
          item={blogEntry}
          thumbSize={thumbSize} 
          key={i} 
          isTagsDetailPage={isTagsDetailPage} 
          activeDisplay={activeDisplay} 
          showMobileImages={showMobileImages}
          showParallax={showParallax}
          imageBorderRadiusForServices={imageBorderRadiusForServices}
          showPageTransition={showPageTransition}
          hashtagsBackgroundColor={hashtagsBackgroundColor}
          showFullWidthOnMobile={showFullWidthOnMobile}
          isLeftBlog={isLeftBlog}
          pageTransitionBgColor={pageTransitionBgColor}
          sectionBackgroundColor={sectionBackgroundColor}
        />
      )
    })}
  </div>
)}

BlogList.propTypes = {
  blogEntries: PropTypes.array,
  isCompact: PropTypes.bool
}

BlogList.defaultProps = {
  blogEntries: [],
  isCompact: true
}

export default BlogList
